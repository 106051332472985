import React from "react";
import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Utils from "../utils/utils";
import Snackbar from "@material-ui/core/Snackbar";
import { Storage } from "aws-amplify";
import awsconfig from "../aws-exports";
import Paper from "@material-ui/core/Paper";

Storage.configure(awsconfig);

 class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      getkey: "segmentmaster",
      getvalue: "",
      putkey: "",
      hashkey:"0847770332",
      hashhash:"s",
      sethashkey:"0847770332",
      sethashhash:"s",
      sethashvalue:"0,2,3",
      delkey: "",
      putvalue: "",
      simphone: "0847770332",
      result: "",
      simphoneresult: "",
      simphoneimp: "0847770332",
      simphoneorderid: "1",
      defaultnetworkid: "Cell C",
      simphoneimpresult: "",
      addresult: [],
      uploadedfiles: [],
      counterResult: "Not done",
      putresult: "",
      user: {
        phone: 123,
        segments: [1, 5, 3],
        optout: false,
        freq: [{ ord: 1, imp: 1 }],
      },
    };
  }
  async segmentcounter() {
    let s = await fetch(
      "https://is3pkph3yf.execute-api.af-south-1.amazonaws.com/production/redisloader/runsegmentcounter"
    );
    let r = await s.text();
    this.setState({ counterResult: r });
  }

  async uploader() {
    this.doSnackBar("Uploading. please wait until DONE");
    // let ext = this.state.file.name.split(".")
    // ext= ext[ext.length-1]
    let filename = this.state.file.name; //Utils.create_UUID()+"."+ext
    await Utils.saveSegmenttos3(this.state.file, this.state.file.name);
    await fetch(
      "https://is3pkph3yf.execute-api.af-south-1.amazonaws.com/production/redisloader/addids?instance=i-0966936e4abadde06&function=addids&s3file=" +
        filename
    );
    this.doSnackBar("done");
  }

  async kickoffadder(input) {
    console.log({ input });
    let s = await fetch(
      "https://is3pkph3yf.execute-api.af-south-1.amazonaws.com/production/redisloader/addids?file=" +
        input.name +
        "&networkid=" +
        input.networkid
    );
    let r = await s.text();
    let addresult = this.state.addresult;
    let uploadedfiles = this.state.uploadedfiles;
    let found = uploadedfiles.find((a) => a.name === input.name);
    if (found) found.result = r;
    addresult.push({ addresult: r, id: input.name });
    console.log({ addresult });
    this.setState({ addresult, uploadedfiles });
  }

  async uploadFileWithStatus(file, newdata, fileqty) {
    const foo = this;
    let name = await Storage.put("segment/" + file.name, file, {
      contentType: file.type,
      progressCallback(progress) {
        foo.doSnackBar(
          "Uploaded: " +
            ((progress.loaded / progress.total) * 100).toFixed(0) +
            "% of file #" +
            newdata +
            "/" +
            fileqty
        );
      },
    });
    return name;
  }

  async uploader2() {
    this.doSnackBar("Reading your file. please wait until DONE");
    let contents = new Promise((res, rej) => {
      const handleFileRead = (e) => {
        const content = fileReader.result;
        res(content);
      };
      const handleerr = (e) => {
        rej(e);
      };
      let fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.onerror = handleerr;
      fileReader.readAsText(this.state.file2);
    });
    let dta = await contents;
    let ids = dta.split("\n").filter((z, i) => z !== "" && i !== 0);
    let newdata = 1;
    let uploadedfiles = [];
    let splitbylinecount = 500000;
    let fileqty = (ids.length / splitbylinecount).toFixed(0);

    while (ids.length > splitbylinecount) {
      let items = ids.splice(splitbylinecount);
      let file = new File([ids.join("\n")], Utils.create_UUID() + ".csv", {
        type: "plain/text",
      });
      let name = await this.uploadFileWithStatus(file, newdata, fileqty);
      name = name.key.replace("segment/", "");
      console.log({ name });
      setTimeout(() => {
        this.kickoffadder({
          name: name,
          networkid: this.state.defaultnetworkid,
        });
      }, 9000);

      uploadedfiles.push({ name, result: "please wait..." });
      this.setState({ uploadedfiles });
      console.log({ uploadedfiles });

      newdata += 1;
      ids = items;
    }
    const foo = this;
    let file = new File([ids.join("\n")], Utils.create_UUID() + ".csv", {
      type: "plain/text",
    });
    let name = await Storage.put("segment/" + file.name, file, {
      contentType: file.type,
      progressCallback(progress) {
        foo.doSnackBar(
          "Uploaded: " +
            ((progress.loaded / progress.total) * 100).toFixed(0) +
            "% of file #" +
            newdata +
            "/" +
            fileqty
        );
      },
    });
    name = name.key.replace("segment/", "");
    console.log({ name });
    setTimeout(() => {
      this.kickoffadder({
        name: name,
        networkid: this.state.defaultnetworkid,
      });
    }, 9000);
    uploadedfiles.push({ name, result: "" });
    console.log({ ids });
    console.log({ newdata });
    console.log({ uploadedfiles });
    ids = null;
    this.setState({ uploadedfiles });
    this.doSnackBar("done");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authState.state !== this.props.authState.state) {
      this.setState({ authState: this.props.authState });
    }
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.state.authState.state !== "signedIn")
        this.props.history.push("/");
      else {
      }
    }, 1000);
  }

  async doDel(input) {
    let url = "https://ngn.vnamobi.com/test/";
    let data = {
      del: true,
      key: input,
    };
    try {
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      let res = await response.text();

      this.setState({ result: res });
    } catch (err) {
      console.log(err);
    }
  }
  async doGet(input) {
    let res = await Utils.doGet(input);
    if (typeof res === "object") res = JSON.stringify(res);
    this.setState({ result: res });
  }
  async doHGet(input,hash) {
    let res = await Utils.doHGet(input,hash);
    if (typeof res === "object") res = JSON.stringify(res);
    this.setState({ result: res });
  }
  async doHSet(input,hash,val) {
    let res = await Utils.doHSet(input,hash,val);
    if (typeof res === "object") res = JSON.stringify(res);
    this.setState({ result: res });
  }
  async doPut(inputkey, inputval) {
    let res = await Utils.doPut(inputkey, inputval);
    if (typeof res === "object") res = JSON.stringify(res);

    this.setState({ result: res });
  }
  async doSimPhone(inputkey) {
    try {
      let res = await Utils.doSimPhone(inputkey);
      this.setState({ result: JSON.stringify(res, null, 4) });
    } catch (err) {
      console.log(err);
    }
  }
  async doSimImpression(inputkey, ord) {
    try {
      let res = await Utils.doSimImpression(inputkey, ord);
      this.setState({ result: JSON.stringify(res, null, 4) });
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Paper
          style={{
            margin: 10,
            padding: 10,
            background: "#aaa",
          }}
        >
          <h1>Result</h1>
          <div>{this.state.result}</div>
        </Paper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
          }}
        >
          <div>Get key</div>
          <TextField
            style={{ marginLeft: 10, width: 200 }}
            label="key"
            defaultValue={this.state.getkey}
            onChange={(change) =>
              this.setState({ getkey: change.target.value })
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.doGet(this.state.getkey);
            }}
          >
            get
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
          }}
        >
          <div>Delete key</div>
          <TextField
            style={{ marginLeft: 10, width: 200 }}
            label="key"
            defaultValue={this.state.delkey}
            onChange={(change) =>
              this.setState({ delkey: change.target.value })
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.doDel(this.state.delkey);
            }}
          >
            Delete
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
          }}
        >
          <div>Put key</div>
          <TextField
            style={{ marginLeft: 10, width: 200 }}
            label="key"
            defaultValue={this.state.putkey}
            onChange={(change) =>
              this.setState({ putkey: change.target.value })
            }
          />
          <TextField
            label="value"
            style={{ marginLeft: 10, width: 200 }}
            defaultValue={this.state.putvalue}
            onChange={(change) =>
              this.setState({ putvalue: change.target.value })
            }
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.doPut(this.state.putkey, this.state.putvalue);
            }}
          >
            put
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
          }}
        >
          <div>Get hash key for users</div>
          <TextField
            style={{ marginLeft: 10, width: 200 }}
            label="key"
            defaultValue={this.state.hashkey}
            onChange={(change) =>
              this.setState({ hashkey: change.target.value })
            }
          />
          <TextField
            label="subkey"
            style={{ marginLeft: 10, width: 200 }}
            defaultValue={this.state.hashhash}
            onChange={(change) =>
              this.setState({ hashhash: change.target.value })
            }
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.doHGet(this.state.hashkey, this.state.hashhash);
            }}
          >
            get hash data
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
          }}
        >
          <div>Set hash key for users</div>
          <TextField
            style={{ marginLeft: 10, width: 200 }}
            label="key"
            defaultValue={this.state.sethashkey}
            onChange={(change) =>
              this.setState({ sethashkey: change.target.value })
            }
          />
          <TextField
            label="subkey"
            style={{ marginLeft: 10, width: 200 }}
            defaultValue={this.state.sethashhash}
            onChange={(change) =>
              this.setState({ sethashhash: change.target.value })
            }
          />
          <TextField
            label="value"
            style={{ marginLeft: 10, width: 200 }}
            defaultValue={this.state.sethashvalue}
            onChange={(change) =>
              this.setState({ sethashvalue: change.target.value })
            }
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.doHSet(this.state.sethashkey, this.state.sethashhash,this.state.sethashvalue);
            }}
          >
            Set hash data
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
          }}
        >
          <div>Simulate engine</div>
          <TextField
            label="Phone number (without +27 or 27)"
            style={{ marginLeft: 10, width: 300 }}
            defaultValue={this.state.simphone}
            onChange={(change) =>
              this.setState({ simphone: change.target.value })
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.doSimPhone(this.state.simphone);
            }}
          >
            get
          </Button>
          {this.state.simphoneresult}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
          }}
        >
          <div>Simulate impression</div>
          <TextField
            label="Phone number (without +27 or 27)"
            style={{ marginLeft: 10, width: 300 }}
            defaultValue={this.state.simphoneimp}
            onChange={(change) =>
              this.setState({ simphoneimp: change.target.value })
            }
          />
          <TextField
            label="orderid"
            defaultValue={this.state.simphoneorderid}
            onChange={(change) =>
              this.setState({ simphoneorderid: change.target.value })
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.doSimImpression(
                this.state.simphoneimp,
                this.state.simphoneorderid
              );
            }}
          >
            get
          </Button>
          {this.state.simphoneimpresult}
        </div>
        <Paper style={{ background: "#eee", margin: 10, padding: 10 }}>
          <h1>Manual tests</h1>
          Test phone number: 0847770332
          <br />
          {`curl -X POST -H "AuthToken:6e3543b0-aa31-429e-a5a3-aa60c90e083a" -H "Content-Type:application/json" -d '{"placements":[{"id":2}],"userKey":"456789","userInfo":{"msisdn":"27847770332","lat":0.0,"long":0.0}}' https://ngn.vnamobi.com/`}
          <br />
          {`curl "https://ngn.vnamobi.com/impression/?orderid=9&msisdn=27847770332"`}

          <h4>Other test values</h4>
          <ul>
            <li>
              livecounterorder1
            </li>
            <li>
              livecounterorder12020-12-22
            </li>
            <li>
              globalSegments
            </li>
            <li>
              segmentmaster
            </li>
            <li>
              segmentcounter
            </li>
            <li>
              hget 0847770332 s
            </li>
            <li>
              hget 0847770332 o7 (s, optout, etc)
            </li>

          </ul>
        
        </Paper>
        <Paper style={{ margin: 10, background: "#ccc", padding: 10 }}>
          <h1>NEW uploader</h1>
          <h2>
            multi column, csv of text. One number per row:
            0821234567,male,jhb,...
          </h2>
          <div style={{ display: "flex" }}>
            <input
              type="file"
              onChange={(z) => {
                this.setState({ file2: z.target.files[0] });
              }}
            />
            <TextField
              label="network/segment id (eg Cell C, SouthernNandosCustomer)"
              value={this.state.defaultnetworkid}
              style={{ width: 500 }}
              onChange={(change) => {
                let user = this.state.defaultnetworkid;
                user = change.target.value;
                this.setState({ defaultnetworkid: user });
              }}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.uploader2();
              }}
            >
              Upload
            </Button>
          </div>
          <Paper style={{ marginTop: 20, background: "#666" }}>
            {this.state.uploadedfiles.map((a, i) => (
              <div key={i}>
                file:{a.name} and result {a.result || ""}
              </div>
            ))}
          </Paper>
          <Paper style={{ marginTop: 20, padding: 10, background: "#666" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.segmentcounter();
              }}
            >
              Trigger segment counter
            </Button>
            <br />
            Segment counter status (takes 6 mins to count). It may time out but
            it is working in the background. Result:
            {this.state.counterResult}
          </Paper>
          <br />
        </Paper>
      </div>
    );
  }
}
export default withRouter(App);
