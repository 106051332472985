import React from "react";
import TextField from "@material-ui/core/TextField";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
export default class ClientPage extends React.Component {
  dayOfTheWeek(input) {
    let dy = new Date(input);
    dy = dy.getDay();
    let days = [
      { day: 0, text: "Sun" },
      { day: 1, text: "Mon" },
      { day: 2, text: "Tue" },
      { day: 3, text: "Wed" },
      { day: 4, text: "Thu" },
      { day: 5, text: "Fri" },
      { day: 6, text: "Sat" },
    ];
    return days.find((z) => z.day === dy).text;
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          marginTop: 10,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: this.props.input.done ? "#eee" : "#fff",
        }}
      >
        Date
        <TextField
          variant="outlined"
          size="small"
          style={{marginLeft:10, marginRight:10}}
          type="date"
          value={this.props.input.date}
          onChange={(change) => {
            // console.log(change.target.value)
            this.props.onChange({
              id: this.props.input.id,
              date: change.target.value||(new Date(Date.now())).toISOString().substr(0,10),
            });
          }}
        />
        <div style={{ width: 50 }}>
          {this.dayOfTheWeek(this.props.input.date)}
        </div>
          <div style={{ width: 120 }}>
                Actual: {" "+this.props.input.impressionsactual+" "}
                  </div>
                  / Target:
        <input
          value={this.props.input.impressionstarget}
          onChange={(change) => {
            this.props.onChangeImpressionsTarget({
              id: this.props.input.id,
              impressionstarget: parseInt(change.target.value||0),
            });
          }}
          style={{ width: 60 }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 0,
          }}
        >
          {[
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
          ].map((itm, idx) => (
            <div
              key={idx}
              style={{
                width: 20,
                marginLeft: 1,
                paddingTop: 0,
                cursor: "pointer",
                height: 30,
                backgroundColor:
                  this.props.input.hours.filter((z) => z === itm).length > 0
                    ? "#4caf50"
                    : "#e0e0e0",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => {
                this.props.onChangeHours({
                  id: this.props.input.id,
                  hour: itm,
                });
              }}
            >
              {itm}
            </div>
          ))}
        </div>
        {!this.props.input.done ? (
          <DeleteForeverIcon
            style={{ cursor: "pointer", marginLeft:50 }}
            onClick={() => this.props.onDelete(this.props.input.id)}
          />
        ) : (
          <div style={{ width: 24 }} />
        )}

      </div>
    );
  }
}
