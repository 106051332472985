import React from "react";
import Utils from "../utils/utils";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import PH from "./phone.png";
import Snackbar from "@material-ui/core/Snackbar";

import GSM7 from "gsm7";
import ImageUploader from "react-images-upload";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imagefile: "",
      imagefileb64: "",
      width: 640,
      height: 1138,
      output: "jpg",
      targetsize: 160,
      compressedPicture: null,
      imagefileObject: null,
      imageAttributes: { size: 0, width: 0, height: 0, type: "" },
      imageAttributesCompressed: { size: 0, width: 0, height: 0, type: "" },
    };
  }

  handleChange(variable, input) {
    let change = this.props.input;
    change[variable] = input;
    this.props.onChange(change);
  }
  addDates(numofdates) {
    for (let i = 0; i < numofdates; i++) {
      let input = this.props.input;
      let today = new Date(Date.now());
      today = today.toISOString().slice(0, 10);
      if (input.dayhourindicator.length === 0) {
        input.dayhourindicator = [
          {
            date: today,
            impressionstarget: 0,
            impressionsactual: 0,
            hours: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            id: 1,
            done: false,
          },
        ];
      } else {
        let maxday = "1999-09-09";
        input.dayhourindicator.forEach((item, i) => {
          if (item.date > maxday) maxday = item.date;
        });
        let maxid = 0;
        input.dayhourindicator.forEach((item, i) => {
          if (item.id > maxid) maxid = item.id;
        });
        let newday = new Date(maxday);
        newday.setHours(newday.getHours() + 24);
        newday = newday.toISOString().slice(0, 10);

        input.dayhourindicator.push({
          date: newday,
          impressionstarget: 0,
          impressionsactual: 0,
          hours: [8, 9, 10, 11, 12, 13, 14, 15, 16],
          id: maxid + 1,
          done: false,
        });
      }

      this.props.onChange(input);
    }
  }
  async picdata(src) {
    let promise = new Promise((res, rej) => {
      let img = new Image();

      img.onload = function (e) {
        res({ width: e.target.naturalWidth, height: e.target.naturalHeight });
      };
      img.onerror = function (e) {
        rej(e);
      };
      img.src = src;
    });
    return promise;
  }

  async blobtob64(blob) {
    let promise = new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          res(reader.result);
        };
      });
    });
    return promise;
  }

  async compress(fileobj, width, height, targetsize, output) {
    // console.log({ fileobj });

    if (fileobj.type !== "image/gif") {
      const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };
      const blob = new Blob([fileobj], { type: fileobj.type });
      let b64 = await blobToBase64(blob);
      this.doSnackBar("Sending image to compression server");
      // console.log({ width, height, targetsize, b64, output });
      let s = await fetch(
        "https://pz4ycudfnf.execute-api.eu-west-1.amazonaws.com/default/imagecompressor",
        {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",

            Accept: "application/json",
          },
          body: JSON.stringify({ width, height, targetsize, b64, output }),
        }
      );
      this.doSnackBar("Done");
      let t = await s.text();
      // t = JSON.parse(t);
      // let ftype= t.split(",")[0].split(";")[0].split(";")[1].split("/")[1]
      // console.log(t);

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return {
          file: new File([u8arr], filename + "." + mime.split("/")[1], {
            type: mime,
          }),
          type: mime,
        };
      }

      let file = dataURLtoFile(t.replace(/"/g, ""), "file");
      const url = window.URL.createObjectURL(file.file);
      let dims = await this.picdata(url);
      this.setState({
        imagefileb64: file.file,
        imagefileObject: file.file,
        imageAttributesCompressed: {
          size: file.file.size,
          type: file.file.type,
          width: dims.width,
          height: dims.height,
        },
      });
      let ip = this.props.input;
      ip.uriimage = t.replace(/"/g, "");
      this.props.onChange(ip);
    } else
      this.doSnackBar(
        "GIF compression is not supported right now OR file size > 5Mb"
      );
  }

  async setsizes(t) {
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }

    let file = dataURLtoFile(t.replace(/"/g, ""), "file.jpg");
    const url = window.URL.createObjectURL(file);
    let dims = await this.picdata(url);
    this.setState({
      imageAttributes: {
        width: dims.width,
        height: dims.height,
        size: file.size,
        type: file.type,
      },
    });
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async download(fileobj) {
    // console.log({ fileobj });
    const url = window.URL.createObjectURL(fileobj);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileobj.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
  getCurrentTargetTotal() {
    let total = 0;
    this.props.input.dayhourindicator.forEach(
      (z) => (total += z.impressionstarget)
    );
    return total;
  }
  getCurrentActualTotal() {
    let total = 0;
    this.props.input.dayhourindicator.forEach(
      (z) => (total += z.impressionsactual)
    );
    return total;
  }

  calcReach(allseg, selseg, allsubseg) {
    let total = 0;
    selseg.forEach((item2, i2) => {
      if (item2.ands.length === 1) {
        let found = allseg.find((z) => z.id === item2.ands[0].segid);
        if (found) total += found.total;
      }
      if (item2.ands.length > 1) {
        let list = item2.ands.map((q) => q.segid).sort((a, b) => a - b);
        let found = allsubseg.find(
          (z) => JSON.stringify(z.s) === JSON.stringify(list)
        );

        if (found) total += found.n;
      }
    });

    return total;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.input !== this.props.input) {
      if (this.props.input.uriimage !== null)
        if (this.props.input.uriimage.indexOf("public"))
          this.getImageFile(this.props.input.uriimage);
    }
  }
  async getImageFile(file) {
    let pic = await Utils.getpicfroms3(file);
    this.setState({ imagefile: pic });
  }
  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Paper style={{ padding: 10, background: "#eee", margin: 10 }}>
          <h1>Test your image </h1>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#eee",
                borderWidth: 0,
                boxShadow: 0,
                backgroundImage: `url(${PH})`,
                backgroundRepeat: "no-repeat",
                height: 570,

                width: 300,
              }}
            >
              <div
                style={{
                  marginLeft: 32,
                  width: 238,
                  overflow: "scroll",
                  height: 330,
                  marginTop: 105,
                  wordWrap: "break-word",
                }}
              >
                <a href={this.props.input.urlredirect}>
                  <img
                    alt={this.props.input.urlredirect}
                    width="100%"
                    src={
                      this.state.imagefileb64 !== ""
                        ? URL.createObjectURL(this.state.imagefileb64)
                        : this.state.imagefile
                        ? this.state.imagefile
                        : ""
                    }
                  />
                </a>
                {GSM7.decode(GSM7.encode(this.props.input.text))}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                variant="outlined"
                label="MMS Text"
                style={{ width: 600 }}
                value={this.props.input.text}
                onChange={(change) => {
                  let input = this.props.input;
                  input.text = change.target.value;
                  this.props.onChange(input);
                }}
              />
              {"Length: " + this.props.input.text.length}
              {"   GSMlength:" +
                GSM7.decode(GSM7.encode(this.props.input.text)).length}
              <TextField
                style={{
                  marginTop: 10,
                  width: 600,
                }}
                variant="outlined"
                label="Click URL"
                value={this.props.input.urlredirect}
                onChange={(change) => {
                  let input = this.props.input;
                  input.urlredirect = change.target.value;
                  this.props.onChange(input);
                }}
              />
              <ImageUploader
                key={Math.random()}
                withIcon={false}
                buttonText="Choose image"
                onChange={(pictureFiles, pictureDataURLs) => {
                  // console.log({ pictureDataURLs });
                  this.setsizes(pictureDataURLs[0]);
                  this.setState({
                    imagefileb64: pictureFiles[0],
                    imagefileObject: pictureFiles[0],
                  });

                  let ip = this.props.input;
                  ip.uriimage = pictureDataURLs[0];
                  this.props.onChange(ip);
                }}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={10242880}
              />
              <Paper
                style={{ padding: 10, marginBottom: 10, background: "#ccc" }}
              >
                <h3>Input file</h3>
                Original dimensions
                <ul>
                  <li>Width: {this.state.imageAttributes.width}</li>
                  <li>Height: {this.state.imageAttributes.height}</li>
                  <li>
                    Size in bytes: {" " + this.state.imageAttributes.size}
                  </li>
                  <li>
                    Size in kbytes:{" "}
                    {" " + (this.state.imageAttributes.size / 1024).toFixed(0)}
                  </li>
                  <li>File type: {" " + this.state.imageAttributes.type}</li>
                </ul>
                {this.state.imageAttributes.size / 1024 > 160
                  ? "File larger than 160kb. You must compress it."
                  : "File is 160kb or less. Good."}
              </Paper>

              <Paper style={{ padding: 10 }}>
                <h3>Compression settings</h3>
                <div>
                  <TextField
                    label="Width"
                    style={{ width: 100 }}
                    value={this.state.width}
                    onChange={(change) => {
                      // let input = this.props.input;
                      // input.frequency = change.target.value;
                      // this.props.onChange(input);
                      this.setState({ width: change.target.value });
                    }}
                  />
                  <TextField
                    label="Height"
                    style={{ width: 100, marginLeft: 5 }}
                    value={this.state.height}
                    onChange={(change) => {
                      this.setState({ height: change.target.value });
                    }}
                  />
                  <TextField
                    label="Target size (in kB)"
                    style={{ width: 100, marginLeft: 5 }}
                    value={this.state.targetsize}
                    onChange={(change) => {
                      this.setState({ targetsize: change.target.value });
                    }}
                  />
                  <TextField
                    label="Output format (jpg or png)"
                    style={{ width: 100, marginLeft: 5 }}
                    value={this.state.output}
                    onChange={(change) => {
                      this.setState({ output: change.target.value });
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: 200, marginLeft: 5 }}
                    disabled={this.state.imagefileObject ? false : true}
                    onClick={() => {
                      this.setState({
                        imageAttributesCompressed: { size: 0, width: 0, height: 0, type: "" },
                      });
                      this.compress(
                        this.state.imagefileObject,
                        this.state.width,
                        this.state.height,
                        this.state.targetsize * 1000,
                        this.state.output
                      );
                    }}
                  >
                    compress
                  </Button>
                </div>
              </Paper>
              <Paper style={{ padding: 10, marginTop: 10, background: "#ccc" }}>
                <h3>Output file</h3>
                New dimensions
                <ul>
                  <li>Width: {this.state.imageAttributesCompressed.width}</li>
                  <li>Height: {this.state.imageAttributesCompressed.height}</li>
                  <li>
                    Size in bytes:{" "}
                    {" " + this.state.imageAttributesCompressed.size}
                  </li>
                  <li>
                    Size in kbytes:{" "}
                    {" " +
                      (
                        this.state.imageAttributesCompressed.size / 1024
                      ).toFixed(0)}
                  </li>
                  <li>
                    File type: {" " + this.state.imageAttributesCompressed.type}
                  </li>
                </ul>
                {this.state.imageAttributesCompressed.size / 1024 > 160
                  ? "File larger than 160kb. You must compress it."
                  : "File is 160kb or less. Good."}
                <Button
                  style={{ width: 50, marginLeft: 5 }}
                  variant="contained"
                  color="primary"
                  disabled={this.state.imagefileObject ? false : true}
                  onClick={() => {
                    this.download(this.state.imagefileObject);
                  }}
                >
                  <GetAppIcon />
                </Button>
              </Paper>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
