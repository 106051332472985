import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Utils from "../utils/utils";
import Snackbar from "@material-ui/core/Snackbar";

class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      clients: [],
      newClientName: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authState.state !== this.props.authState.state) {
      this.setState({ authState: this.props.authState });

      this.getClients();
    }
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.state.authState.state !== "signedIn")
        this.props.history.push("/");
      else this.getClients();
    }, 1000);
  }

  async getClients() {
    let clients = await Utils.genericList("listVnaLabels", {});
    // console.log(clients);
    if (clients) this.setState({ clients: clients.data.listVNALabels.items });
  }
  async addClient(name) {
    let data = {
      name,
    };
     await Utils.genericMutation("createVnaLabel", data);
    this.doSnackBar("Added label");
    this.getClients();
    // this.setState({ clients });
  }
  render() {
    return (
    <div style={{ margin: 20, color: "#666" }}>
            <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <h1>labels</h1>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Label name</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.clients.map((z, idx) => (
              <TableRow key={idx}>
                <TableCell>{z.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <h1>Add Label</h1>
        <TextField
          style={{ marginRight: 10 }}
          value={this.state.newClientName}
          onChange={(change) => {
            this.setState({ newClientName: change.target.value });
          }}
          id="outlined-basic"
          label="Client name"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.addClient(this.state.newClientName);
            this.setState({ newClientName: "" });
          }}
        >
          Add new label
        </Button>
      </div>
    );
  }
}
export default withRouter(ClientPage);
