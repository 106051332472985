import React from "react";
import Utils from "../utils/utils";

export default class PreviewImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: "",
    };
  }

  componentDidMount() {
    this.collectImage(this.props.image);
  }

  async collectImage(input) {
    if (input !== null && input !== undefined) {
      let pic = await Utils.getpicfroms3(input);
      this.setState({ image: pic });
    }
  }

  render() {
    return (
      <div>
        <img
          alt=""
          src={this.state.image}
          style={{ maxWidth:"50px", maxHeight: "80px", display: "block", marginRight:5 }}
        />
      </div>
    );
  }
}
