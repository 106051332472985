import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Utils from "../utils/utils";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      clients: [],
      labels: [],
      selectedLabelid: "",
      newClientName: "",
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authState.state !== this.props.authState.state) {
      this.setState({ authState: this.props.authState });
this.getLabels();
      this.getClients();
    }
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.state.authState.state !== "signedIn")
        this.props.history.push("/");
      else {
        this.getClients();
        this.getLabels();
      }
    }, 1000);
  }

  async getClients() {
    let clients = await Utils.genericList("listVnaClients", {});
    console.log(clients);
    if (clients) this.setState({ clients: clients.data.listVNAClients.items });
  }
  async addClient(name) {
    let data = {
      name,
      vNAClientLabelId: this.state.selectedLabelid,
    };
    await Utils.genericMutation("createVnaClient", data);
    this.doSnackBar("Added Client");
    this.getClients();
    // this.setState({ clients });
  }
  async getLabels() {
    let clients = await Utils.genericList("listVnaLabels", {});
    // console.log(clients);
    if (clients) this.setState({ labels: clients.data.listVNALabels.items });
  }
  render() {
    return (
      <div style={{ margin: 20, color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <h1>Clients</h1>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Client name</b>
              </TableCell>
              <TableCell>
                <b>Label name</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.clients.map((z, idx) => (
              <TableRow key={idx}>
                <TableCell>{z.name}</TableCell>
                <TableCell>{z.label.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <h1>Add Client</h1>
        <TextField
          style={{ marginRight: 10 }}
          value={this.state.newClientName}
          onChange={(change) => {
            this.setState({ newClientName: change.target.value });
          }}
          id="outlined-basic"
          label="Client name"
          variant="outlined"
        />
        <FormControl>
          <InputLabel>labels</InputLabel>
          <Select
            id="Add to label"
            style={{ width: 100 }}
            value={this.state.selectedLabelid || ""}
            onChange={(change) => {
              // let users = this.state.users;
              // let found = users.filter(
              //   (a) => a.uid === uitem.uid
              // )[0];
              // if (found.selectedClient === null)
              //   found.selectedClient = "";
              // found.selectedClient = change.target.value;
              this.setState({ selectedLabelid: change.target.value });
            }}
          >
            {this.state.labels.map((citem, cindex) => (
              <MenuItem key={cindex} value={citem.id}>
                {citem.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.addClient(this.state.newClientName);
            this.setState({ newClientName: "" });
          }}
        >
          Add new client
        </Button>
      </div>
    );
  }
}
export default withRouter(ClientPage);
