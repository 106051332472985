import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Utils from "../utils/utils";
import Snackbar from "@material-ui/core/Snackbar";
import { Storage } from "aws-amplify";
import awsconfig from "../aws-exports";
Storage.configure(awsconfig);

class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      file: "",
      defaultnetworkid: "",
      addresult: [],
      uploadedfiles: [],
      counterResult: "Not done",
      disableupload: false,
      disableseg: false,
    };
  }
  async kickoffadder(input) {
    // console.log({ input });
    let s = await fetch(
      "https://is3pkph3yf.execute-api.af-south-1.amazonaws.com/production/redisloader/addids?file=" +
        input.name +
        "&networkid=" +
        input.networkid
    );
    let r = await s.text();
    let addresult = this.state.addresult;
    let uploadedfiles = this.state.uploadedfiles;
    let found = uploadedfiles.find((a) => a.name === input.name);
    if (found) found.result = r;
    addresult.push({ addresult: r, id: input.name });
    // console.log({ addresult });
    this.setState({ addresult, uploadedfiles });
  }
  async segmentcounter() {
    let s = await fetch(
      "https://is3pkph3yf.execute-api.af-south-1.amazonaws.com/production/redisloader/runsegmentcounter"
    );
    let r = await s.text();
    this.setState({ counterResult: r });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authState.state !== this.props.authState.state) {
      this.setState({ authState: this.props.authState });
    }
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.state.authState.state !== "signedIn")
        this.props.history.push("/");
      else {
      }
    }, 1000);
  }
  async uploadFileWithStatus(file, newdata, fileqty) {
    const foo = this;
    let name = await Storage.put("segment/" + file.name, file, {
      contentType: file.type,
      progressCallback(progress) {
        foo.doSnackBar(
          "Uploaded: " +
            ((progress.loaded / progress.total) * 100).toFixed(0) +
            "% of file #" +
            newdata +
            "/" +
            fileqty
        );
      },
    });
    return name;
  }
  async uploader2() {
    this.doSnackBar("Reading your file. please wait until DONE");
    let contents = new Promise((res, rej) => {
      const handleFileRead = (e) => {
        const content = fileReader.result;
        res(content);
      };
      const handleerr = (e) => {
        rej(e);
      };
      let fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.onerror = handleerr;
      fileReader.readAsText(this.state.file2);
    });
    let dta = await contents;
    let ids = dta.split("\n").filter((z, i) => z !== "" && i !== 0);
    let newdata = 1;
    let uploadedfiles = [];
    let splitbylinecount = 500000;
    let fileqty = (ids.length / splitbylinecount).toFixed(0);
    while (ids.length > splitbylinecount) {
      let items = ids.splice(splitbylinecount);
      let file = new File([ids.join("\n")], Utils.create_UUID() + ".csv", {
        type: "plain/text",
      });
        let name = await this.uploadFileWithStatus(file, newdata, fileqty);
      name = name.key.replace("segment/", "");
      // console.log({ name });
      setTimeout(() => {
        this.kickoffadder({
          name: name,
          networkid: this.state.defaultnetworkid,
        });
      }, 9000);

      uploadedfiles.push({ name, result: " please wait..." });
      this.setState({ uploadedfiles });
      // console.log({ uploadedfiles });

      newdata += 1;
      ids = items;
    }
    const foo = this;
    let file = new File([ids.join("\n")], Utils.create_UUID() + ".csv", {
      type: "plain/text",
    });
    let name = await Storage.put("segment/" + file.name, file, {
      contentType: file.type,
      progressCallback(progress) {
        foo.doSnackBar(
          "Uploaded: " +
            ((progress.loaded / progress.total) * 100).toFixed(0) +
            "% of file #" +
            newdata +
            "/" +
            fileqty
        );
      },
    });
    name = name.key.replace("segment/", "");
    // console.log({ name });
    setTimeout(() => {
      this.kickoffadder({
        name: name,
        networkid: this.state.defaultnetworkid,
      });
    }, 9000);
    uploadedfiles.push({ name, result: " please wait..." });
    // console.log({ ids });
    // console.log({ newdata });
    // console.log({ uploadedfiles });
    ids = null;
    this.setState({ uploadedfiles });
    this.doSnackBar("done");
  }

  render() {
    return (
      <div style={{ margin: 20, color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <h1>Segment uploader</h1>
        <h2>
          Single column, csv of text. with header, One number per row:
          0821234567
        </h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            label="Network/segment id (eg Cell C, SouthernNandosCustomer)"
            variant="outlined"
            style={{ width: 500 }}
            value={this.state.defaultnetworkid}
            onChange={(change) =>
              this.setState({ defaultnetworkid: change.target.value })
            }
          />
          <input
            type="file"
            onChange={(z) => {
              console.log(z.target.files);
              this.setState({ file2: z.target.files[0] });
            }}
          />
          <Button
            color="primary"
            disabled={this.state.disableupload}
            variant="contained"
            onClick={() => {
              this.setState({ disableupload: true });
              this.uploader2();
            }}
          >
            Upload
          </Button>
        </div>
        <br />
        <br />
        {this.state.uploadedfiles.map((a, i) => (
          <div key={i}>
            File: {a.name} uploaded! Adding to DB. DB adding result:{" "}
            {a.result || ""}
          </div>
        ))}
        <Button
          disabled={this.state.disableseg}
          color="primary"
          variant="contained"
          onClick={() => {
            this.setState({ disableseg: true });
            this.segmentcounter();
          }}
        >
          Trigger segment counter
        </Button>
        <br />
        <br />
        <br />
        Segment counter status (takes 6 mins to count). Result:
        {this.state.counterResult}
      </div>
    );
  }
}
export default withRouter(ClientPage);
