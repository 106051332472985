import React from "react";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Utils from "../utils/utils";
import TextField from "@material-ui/core/TextField";
import HourMarks from "../components/hourmarks";
import SegmentPicker from "../components/segmentpicker";
import SegmentPicker2 from "../components/selector";
import Snackbar from "@material-ui/core/Snackbar";
import GetAppIcon from "@material-ui/icons/GetApp";
import Paper from "@material-ui/core/Paper";
import PH from "./phone.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import IAB from "./Untitled.png"
import GSM7 from "gsm7";
import ImageUploader from "react-images-upload";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adjustor: 0,
      weekendratio: "70/100/100/100/100/125/90",
      imagefile: "",
      imagefileb64: "",
      width: 640,
      height: 1138,
      output: "jpg",
      targetsize: 160,
      compressedPicture: null,
      imagefileObject: null,
      imageAttributes: { size: 0, width: 0, height: 0, type: "" },
      imageAttributesCompressed: { size: 0, width: 0, height: 0, type: "" },
    };
  }

  handleChange(variable, input) {
    let change = this.props.input;
    change[variable] = input;
    this.props.onChange(change);
  }
  addDates(numofdates) {
    for (let i = 0; i < numofdates; i++) {
      let input = this.props.input;
      let today = new Date(Date.now());
      today = today.toISOString().slice(0, 10);
      if (input.dayhourindicator.length === 0) {
        input.dayhourindicator = [
          {
            date: today,
            impressionstarget: 0,
            impressionsactual: 0,
            hours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            id: 1,
            done: false,
          },
        ];
      } else {
        let maxday = "1999-09-09";
        input.dayhourindicator.forEach((item, i) => {
          if (item.date > maxday) maxday = item.date;
        });
        let maxid = 0;
        input.dayhourindicator.forEach((item, i) => {
          if (item.id > maxid) maxid = item.id;
        });
        let newday = new Date(maxday);
        newday.setHours(newday.getHours() + 24);
        newday = newday.toISOString().slice(0, 10);

        input.dayhourindicator.push({
          date: newday,
          impressionstarget: 0,
          impressionsactual: 0,
          hours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
          id: maxid + 1,
          done: false,
        });
      }

      this.props.onChange(input);
    }
  }

  getCurrentTargetTotal() {
    let total = 0;
    this.props.input.dayhourindicator.forEach(
      (z) => (total += z.impressionstarget)
    );
    return total;
  }
  getCurrentActualTotal() {
    let total = 0;
    this.props.input.dayhourindicator.forEach(
      (z) => (total += z.impressionsactual)
    );
    return total;
  }

  calcReach(allseg, selseg, allsubseg) {
    let total = 0;
    selseg.forEach((item2, i2) => {
      if (item2.ands.length === 1) {
        let found = allseg.find((z) => z.id === item2.ands[0].segid);
        if (found) total += found.total;
      }
      if (item2.ands.length > 1) {
        let list = item2.ands.map((q) => q.segid).sort((a, b) => a - b);
        let found = allsubseg.find(
          (z) => JSON.stringify(z.s) === JSON.stringify(list)
        );

        if (found) total += found.n;
      }
    });

    return total;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.input !== this.props.input) {
      this.setState({
        adjustor: this.props.input.dayhourindicator.reduce(
          (a, b) => a + b.impressionstarget,
          0
        ),
      });
      if (this.props.input.uriimage !== null) {
        if (this.props.input.uriimage.indexOf("public") > -1) {
          // console.log(this.props.input.uriimage);
          this.getImageFile(this.props.input.uriimage);
        }
      }
    }
  }

  async picdata(src) {
    let promise = new Promise((res, rej) => {
      let img = new Image();

      img.onload = function (e) {
        res({ width: e.target.naturalWidth, height: e.target.naturalHeight });
      };
      img.onerror = function (e) {
        rej(e);
      };
      img.src = src;
    });
    return promise;
  }

  async blobtob64(blob) {
    let promise = new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          res(reader.result);
        };
      });
    });
    return promise;
  }

  async compress(fileobj, width, height, targetsize, output) {
    if (fileobj.type !== "image/gif") {
      // console.log({ fileobj });
      const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };
      const blob = new Blob([fileobj], { type: fileobj.type });
      let b64 = await blobToBase64(blob);
      // console.log({ b64 });
      this.doSnackBar("Sending image to compression server");
      let s = await fetch(
        "https://pz4ycudfnf.execute-api.eu-west-1.amazonaws.com/default/imagecompressor",
        {
          method: "POST",
          mode: "cors",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",

            Accept: "application/json",
          },
          body: JSON.stringify({
            width,
            height,
            targetsize,
            b64,
            output,
          }),
        }
      );
      this.doSnackBar("Done");
      let t = await s.text();
      // t = JSON.parse(t);
      // let ftype= t.split(",")[0].split(";")[0].split(";")[1].split("/")[1]
      // console.log(t);

      function dataURLtoFile(dataurl, filename) {
        // console.log({dataurl, filename});
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        // console.log(mime );
        // console.log(arr[0]);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return {
          file: new File(
            [u8arr],
            filename + "." + mime.split("/")[1].replace("jpeg", "jpg"),
            {
              type: mime,
            }
          ),
          type: mime,
        };
      }

      let file = dataURLtoFile(t.replace(/"/g, ""), "file");
      // console.log({ file });
      const url = window.URL.createObjectURL(file.file);
      let dims = await this.picdata(url);
      this.setState({
        imagefileObject: file.file,
        imageAttributesCompressed: {
          size: file.file.size,
          type: file.file.type,
          width: dims.width,
          height: dims.height,
        },
      });
      let ip = this.props.input;
      ip.uriimage = t.replace(/"/g, "");
      // console.log({ ip });
      ip.text=JSON.stringify({w:dims.width,h:dims.height})
      this.props.onChange(ip);
    } else this.doSnackBar("GIF compression is not supported right now");
  }

  async setsizes(t) {
    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }

    let file = dataURLtoFile(t.replace(/"/g, ""), "file.jpg");
    const url = window.URL.createObjectURL(file);
    let dims = await this.picdata(url);
    this.setState({
      imageAttributes: {
        width: dims.width,
        height: dims.height,
        size: file.size,
        type: file.type,
      },
    });
    return dims
  }

  async download(fileobj) {
    // console.log({ fileobj });
    const url = window.URL.createObjectURL(fileobj);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileobj.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  async getImageFile(file) {
    let pic = await Utils.getpicfroms3(file);
    // console.log({ pic });
    let filetype = file.split(".")[1];
    let blob = await fetch(pic).then((r) => r.blob());
    let fileObject = new File([blob], file.split("/")[1], {
      type: "image/" + filetype,
    });
    let compressorObject = {};
    // console.log({ fileObject });
    let dims = await this.picdata(pic);
    // console.log({ dims });

    this.setState({
      imagefile: URL.createObjectURL(fileObject),
      imagefileObject: fileObject,

      imageAttributes: {
        size: fileObject.size,
        width: dims.width,
        height: dims.height,
        type: fileObject.type,
      },
    });
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  testChar(char) {
    if (char.charCodeAt(0) < 32 || char.charCodeAt(0) > 126) {
      console.log(char.charCodeAt(0));
      console.log(char);

      return false;
    } else return true;
  }

  testString(str) {
    if (str.length === 0) return true;
    let ret = str.split("").map((z) => this.testChar(z));
    if (ret.filter((z) => z === false).length > 0) return false;
    else return true;
  }

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <h1>Add order</h1>
        <Paper style={{ padding: 10, background: "#eee", margin: 10 }}>
          <h1>Order admin</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ marginRight: 10 }}
              value={this.props.input.name}
              onChange={(change) => {
                let input = this.props.input;
                input.name = change.target.value.trim();
                this.props.onChange(input);
              }}
              id="outlined-basic"
              label="Order name"
              variant="outlined"
            />
            <TextField
              style={{ marginRight: 10 }}
              value={this.props.campaigns.name ? this.props.campaigns.name : ""}
              disabled={true}
              id="outlined-basic"
              label="Campaign name"
              variant="outlined"
            />

            <div>Active</div>
            <Switch
              checked={
                this.props.input.active ? this.props.input.active : false
              }
              onChange={(change) =>
                this.handleChange("active", change.target.checked)
              }
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />

            <TextField
              label="Frequency cap"
              variant="outlined"
              value={this.props.input.frequency}
              onChange={(change) => {
                let input = this.props.input;
                input.frequency = change.target.value;
                this.props.onChange(input);
              }}
            />
          </div>
        </Paper>
      {true?null:  <Paper style={{ padding: 10, background: "#eee", margin: 10 }}>
          <h1>MTN billing</h1>
          <h5>
            Only used in populating EDR records. Will not be applied to Cell C
            impressions.
          </h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Order type</FormLabel>
              <RadioGroup
                row
                aria-label="billing"
                name="billing"
                value={this.props.input.billing.type}
                onChange={(e) => {
                  // console.log(e.target.value);
                  let input = this.props.input;
                  input.billing.type = e.target.value;
                  this.props.onChange(input);
                }}
              >
                <FormControlLabel
                  value="thirdparty"
                  control={<Radio />}
                  label="3rd party"
                />
                <FormControlLabel
                  value="contentpartner"
                  control={<Radio />}
                  label="Content Partner"
                />
                <FormControlLabel
                  value="internal"
                  control={<Radio />}
                  label="Internal"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              style={{ marginRight: 10 }}
              value={this.props.input.billing.value}
              onChange={(e) => {
                // console.log(e.target.value);
                let input = this.props.input;
                input.billing.value = e.target.value;
                this.props.onChange(input);
              }}
              id="outlined-basic"
              label="Billing rate CPM MTN to VNA"
              variant="outlined"
            />
          </div>
        </Paper>}

        <Paper style={{ padding: 10, background: "#eee", margin: 10 }}>
          <h1>Order schedule</h1>
          {this.props.input.dayhourindicator.map((itm, idx) => (
            <HourMarks
              input={itm}
              key={idx}
              onDelete={(id) => {
                let input = this.props.input;
                input.dayhourindicator = input.dayhourindicator.filter(
                  (z) => z.id !== id
                );
                this.props.onChange(input);
              }}
              onChange={(change) => {
                let input = this.props.input;
                let found = input.dayhourindicator.find(
                  (z) => z.id === change.id
                );
                if (found) found.date = change.date;

                this.props.onChange(input);
              }}
              onChangeImpressionsTarget={(change) => {
                let input = this.props.input;
                let found = input.dayhourindicator.find(
                  (z) => z.id === change.id
                );
                if (found) found.impressionstarget = change.impressionstarget;

                this.props.onChange(input);
              }}
              onChangeHours={(change) => {
                let input = this.props.input;
                let found = input.dayhourindicator.find(
                  (z) => z.id === change.id
                );
                if (found) {
                  let currenthours = found.hours;

                  if (
                    currenthours.filter((z) => z === change.hour).length === 0
                  )
                    found.hours.push(change.hour);
                  else
                    found.hours = found.hours.filter((z) => z !== change.hour);
                }

                this.props.onChange(input);
              }}
            />
          ))}

          <Button
            onClick={() => {
              this.addDates(1);
            }}
          >
            <AddIcon />
            Add 1
          </Button>
          <Button
            onClick={() => {
              this.addDates(10);
            }}
          >
            <AddIcon />
            Add 10
          </Button>
          <div>
            <div
              style={{ marginTop: 10, display: "flex", flexDirection: "row" }}
            >
              <div>Actual impressions: {this.getCurrentActualTotal()}</div>
              <div style={{ marginLeft: 20 }}>
                Targeted impressions:{" "}
                {this.props.input.dayhourindicator.reduce(
                  (a, b) => a + b.impressionstarget,
                  0
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                style={{ marginRight: 10 }}
                value={this.state.adjustor}
                onChange={(change) => {
                  // let tgt = this.props.input.dayhourindicator.reduce(
                  //   (a, b) => a + b.impressionsactual,
                  //   0
                  // );
                  let input = parseInt(change.target.value || 0);
                  // console.log({ tgt, input });
                  // if (tgt < input) tgt = input;
                  this.setState({
                    adjustor: input,
                  });
                }}
                id="outlined-basic"
                label="Adjust target"
                variant="outlined"
              />
              <TextField
                style={{ marginRight: 10 }}
                value={this.state.weekendratio}
                onChange={(change) => {
                  this.setState({ weekendratio: change.target.value });
                }}
                id="outlined-basic"
                label="S/M/T/W/T/F/S Ratio"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let input = this.props.input;

                  let newtotal = this.state.adjustor;
                  let tgt = this.props.input.dayhourindicator.reduce(
                    (a, b) => a + b.impressionsactual,
                    0
                  );
                  if (newtotal < tgt) {
                    newtotal = tgt;
                    this.setState({ adjustor: tgt });
                  }

                  // console.log({
                  //   input,
                  //   actuals: input.dayhourindicator.reduce(
                  //     (a, b) => a + b.impressionsactual,
                  //     0
                  //   ),
                  // });
                  // let newvalues = [];
                  let todaystring = new Date(Date.now());
                  todaystring.setHours(todaystring.getHours() + 2);
                  todaystring = todaystring.toISOString().substr(0, 10);
                  // console.log({ todaystring });
                  let newtarget = newtotal - tgt;
                  // console.log({ newtarget });

                  let dayratios = this.state.weekendratio
                    .split("/")
                    .map((z) => (z = parseFloat(z)));

                  let totweights = 0;
                  // function getdow(inputdate, daymatch) {
                  //   let dt = new Date(inputdate).getDay();
                  //   return daymatch.find((a) => a.day === dt).ratio;
                  // }
                  input.dayhourindicator.forEach((z) => {
                    if (z.date >= todaystring) {
                      let dt = new Date(z.date).getDay();
                      totweights += dayratios[dt];
                    }
                  });

                  input.dayhourindicator.forEach((z) => {
                    if (z.date < todaystring)
                      z.impressionstarget = z.impressionsactual;
                    else {
                      let dt = new Date(z.date).getDay();
                      z.impressionstarget = parseInt(
                        (dayratios[dt] / totweights) * newtarget
                      );
                    }
                  });

                  let rem =
                    newtotal -
                    input.dayhourindicator.reduce(
                      (a, b) => a + b.impressionstarget,
                      0
                    );
                  input.dayhourindicator[
                    input.dayhourindicator.length - 1
                  ].impressionstarget += rem;
                  console.log({ newtotal, rem });
                  // input.dayhourindicator.forEach((z) => {
                  //   if (z.date > todaystring) {
                  //     let dt = new Date(z.date).getDay();
                  //     z.impressionstarget = parseInt(
                  //       (dayratios[dt] / totweights) * newtarget
                  //     );
                  //     used += z.impressionstarget;
                  //   }
                  // });
                  // input.dayhourindicator[
                  //   input.dayhourindicator.length-1
                  // ].impressionstarget += newtotal - used;

                  // else if (z.date === todaystring)
                  //   z.impressionstarget = z.impressionsactual;
                  // else {
                  //   z.impressionstarget = 0
                  // parseInt(
                  //   (newtarget / totweights) * getdow(z.date, days)
                  // ));
                  // newvalues.push(z);
                  //   }
                  // });
                  // newvalues.forEach(
                  //   (z) => (totweights += getdow(z.date, days))
                  // );
                  // newvalues.forEach(
                  //   (z) =>
                  //     (z.impressionstarget = parseInt(
                  //       (newtotal / totweights) * getdow(z.date, days)
                  //     ))
                  // );
                  // input.dayhourindicator.forEach((z) => {
                  // newvalues.forEach((q) => {
                  //   if (z.id === q.id)
                  //     z.impressionstarget = q.impressionstarget;
                  // });
                  // });
                  this.props.onChange(input);
                }}
              >
                Adjust & balance
              </Button>
            </div>
          </div>
        </Paper>
        <Paper style={{ padding: 10, background: "#eee", margin: 10 }}>
          <SegmentPicker2
            input={this.props.input}
            onChange={(input) => {
              // console.log({ input });
              this.props.onChange(input);
            }}
          />
        </Paper>

        <Paper style={{ padding: 10, background: "#eee", margin: 10 }}>
          <h1>Preview</h1>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#eee",
                borderWidth: 0,
                boxShadow: 0,
                height: 570,

                width: 300,
              }}
            >
              <div
                style={{
                  marginLeft: 32,
                  width: 238,
                  overflow: "scroll",
                  height: 330,
                  marginTop: 105,
                  wordWrap: "break-word",
                }}
              >
                <a href={this.props.input.urlredirect}>
                  <img
                    alt={this.props.input.urlredirect}
                    width="100%"
                    src={
                      this.state.imagefileb64 !== ""
                        ? URL.createObjectURL(this.state.imagefileb64)
                        : this.state.imagefile
                        ? this.state.imagefile
                        : ""
                    }
                  />
                </a>

              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >

              <div
                style={{
                  marginTop: 10,

                  width: 640,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >

              </div>


              <TextField
                style={{
                  marginTop: 10,
                  width: 640,
                }}
                variant="outlined"
                label="Click URL"
                value={this.props.input.urlredirect}
                onChange={(change) => {
                  let input = this.props.input;
                  input.urlredirect = change.target.value;
                  this.props.onChange(input);
                }}
              />
              <ImageUploader
                key={Math.random()}
                buttonText="Choose image"
                onChange={async (pictureFiles, pictureDataURLs) => {
                  console.log({ pictureFiles });
                  console.log({ pictureDataURLs });

                  let dims  =  await this.setsizes(pictureDataURLs[0]);
                  this.setState({
                    imagefileb64: pictureFiles[0],
                    imagefileObject: pictureFiles[0],
                  });

                  let ip = this.props.input;
                  ip.uriimage = pictureDataURLs[0];
                  console.log({dims})
                  ip.text=JSON.stringify({w:dims.width,h:dims.height})

                  this.props.onChange(ip);



                }}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={10242880}
              />
              <Paper
                style={{ padding: 10, marginBottom: 10, background: "#ccc" }}
              >
                <h3>Input file</h3>
                Original dimensions
                <ul>
                  <li>Width: {this.state.imageAttributes.width}</li>
                  <li>Height: {this.state.imageAttributes.height}</li>
                  <li>
                    Size in bytes: {" " + this.state.imageAttributes.size}
                  </li>
                  <li>
                    Size in kbytes:{" "}
                    {" " + (this.state.imageAttributes.size / 1024).toFixed(0)}
                  </li>
                  <li>File type: {" " + this.state.imageAttributes.type}</li>
                </ul>

              </Paper>
              <Paper
                style={{ padding: 10, marginBottom: 10, background: "#ccc" }}
              >
                <h3>Typical dimensions</h3>
              <img alt="" src={IAB}/>

              </Paper>

            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
