import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
export default class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectorOpen: false,
      chosenGroup: null,
    };
  }
  calcTotal(name) {
    let number = this.props.segmentmaster.find((a) => a.name === name).id;
    let total = 0;
    this.props.segmentcounter.forEach((a) => {
      if (a.ids.split(",").find((z) => z === "" + number)) total += a.count;
    });
    return total;
  }

  prettyNumber(input) {
    if (input >= 1000000) return (input / 1000000).toFixed(1) + "m";
    if (input >= 1000) return (input / 1000).toFixed(1) + "k";
    if (input < 1000) return input;
  }

  getName(num) {
    return this.props.segmentmaster.find((a) => a.id === num).name;
  }

  checkForSegInPopList(pop, seg) {
    let newpop = [];
    pop.forEach((z) => {
      let id = z.ids.split(",");
      if (id.find((a) => a === "" + seg)) newpop.push(z);
    });
    return newpop;
  }

  calcReach(arr) {
    let total = 0;
    if (this.props.segmentcounter) {
      let population = Object.assign([], this.props.segmentcounter);
      arr.forEach((q) => {
        population = this.checkForSegInPopList(population, q);
      });
      population.forEach((z) => (total += z.count));
    }
    return arr.length > 0 ? total : 0;
  }

  addReach(a) {
    if (a.length > 0) {
      let r = a.map((z) => this.calcReach(z.segments));

      return r.reduce((a, b) => (a += b));
    } else return 0;
  }

  render() {
    return (
      <div style={{}}>
        <Dialog
          onClose={() =>
            this.setState({ selectorOpen: false, chosenGroup: null })
          }
          aria-labelledby="simple-dialog-title"
          open={this.state.selectorOpen}
        >
          <DialogTitle id="simple-dialog-title">Add new group</DialogTitle>
          <div
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {(this.props.segmentmaster || [])
              .sort((a, b) => {
                if (a.name > b.name) return 1;
                else if (a.name < b.name) return -1;
                else return 0;
              })
              .map((a, idx) => (
                <Button
                  size="small"
                  key={idx}
                  color="primary"
                  variant="contained"
                  style={{ margin: 5 }}
                  onClick={() => {
                    let input = this.props.input;
                    let found = input.segments.find(
                      (z) => z.id === this.state.chosenGroup
                    );
                    found.segments.push(a.id);
                    found.segments = found.segments.sort((a, b) => a - b);
                    this.props.onChange(input);
                  }}
                >
                  <div style={{ textTransform: "none" }}>
                    {a.name + " : " + this.prettyNumber(this.calcTotal(a.name))}
                  </div>
                </Button>
              ))}
          </div>
          <Button
            style={{ margin: 10 }}
            color="primary"
            variant="contained"
            onClick={() =>
              this.setState({ selectorOpen: false, chosenGroup: null })
            }
          >
            Close
          </Button>
        </Dialog>
        <h1>
          Target groups{" "}
          {this.prettyNumber(this.addReach(this.props.input.segments))}
        </h1>

        {this.props.input.segments.map((z, idz) => (
          <Card key={idz} style={{ marginTop: 10, marginBottom: 10 }}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Segment size:
                {" " + this.prettyNumber(this.calcReach(z.segments)) + " "}
                {(z.segments || []).map((x, idx) => (
                  <Chip
                    key={idx}
                    style={{ marginLeft: 5 }}
                    label={
                      this.getName(x) +
                      " (" +
                      this.prettyNumber(this.calcTotal(this.getName(x))) +
                      ") "
                    }
                    onDelete={() => {
                      let input = this.props.input;
                      input.segments[z.id].segments = input.segments[
                        z.id
                      ].segments.filter((r) => r !== x);
                      this.props.onChange(input);
                    }}
                  />
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    this.setState({ selectorOpen: true, chosenGroup: z.id });
                  }}
                >
                  <GroupAddIcon />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    let input = this.props.input;
                    input.segments = input.segments.filter(
                      (w) => w.id !== z.id
                    );
                    this.props.onChange(input);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            let input = this.props.input;
            let id = 0;
            if (input.segments.length > 0) {
              input.segments.sort((a, b) => a.id - b.id);
              id = input.segments[input.segments.length - 1].id + 1;
            }
            input.segments.push({ id, segments: [] });
            this.props.onChange(input);
          }}
        >
          Add group
        </Button>
      </div>
    );
  }
}
