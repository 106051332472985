import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
API.configure(awsconfig);
Storage.configure(awsconfig);

const Utils = {
  async getUserName() {
    return await Auth.currentAuthenticatedUser();
  },
  async genericMutation(table, input) {
    return await API.graphql(
      graphqlOperation(mutations[table], {
        input,
      })
    );
  },
  firstLetterUpperCase(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  },
  create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  },

  async doGet(input) {
    let url = "https://ngn.vnamobi.com/test/";
    let data = {
      get: true,
      key: input,
    };
    try {
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      let res = await response.json();
      // console.log({res})
      return res;
    } catch (err) {
      console.log("err");
      return err;
    }
  },
  async doHGet(input,hash) {
    let url = "https://ngn.vnamobi.com/test/";
    let data = {
      hget: true,
      key: "u"+input,
      value:hash
    };
    try {
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      let res = await response.json();
      // console.log({res})
      return res;
    } catch (err) {
      console.log("err");
      return err;
    }
  },
  async doHSet(input,hash,val) {
    let url = "https://ngn.vnamobi.com/test/";
    let data = {
      hset: true,
      key: "u"+input,
      subhash:hash,
      value:val
    };
    try {
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      let res = await response.json();
      // console.log({res})
      return res;
    } catch (err) {
      console.log("err");
      return err;
    }
  },
  async doDel(input) {
    let url = "https://ngn.vnamobi.com/test/";
    let data = {
      del: true,
      key: input,
    };
    try {
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      let res = await response.json();
      return JSON.parse(res);
    } catch (err) {
      return err;
    }
  },
  async doPut(inputkey, inputval) {
    let url = "https://ngn.vnamobi.com/test/";
    let data = {
      put: true,
      key: inputkey,
      value: inputval,
    };
    try {
      let response = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          AuthToken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      return await response.json();
    } catch (err) {
      return err;
    }
  },
  async doSimPhone(inputkey) {
    let url = "https://ngn.vnamobi.com/";
    let data = {
      userInfo:{msisdn: "" + inputkey}
    };
    try {
      let response = await fetch(url, {
        body: JSON.stringify(data),
        method: "POST",
        // mode: "cors",

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authtoken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
        },
      });
      return await response.json();

      // this.setState({ simphoneresult: JSON.stringify(res, null, 4) });
    } catch (err) {
      console.log(err);
    }
  },
  async doSimImpression(inputkey, ord) {
    let url = "https://ngn.vnamobi.com/impression/?orderid="+ord+"&msisdn="+inputkey;

    try {
      let response = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          authtoken: "6e3543b0-aa31-429e-a5a3-aa60c90e083a",
          Accept: "application/json",
        },

      });

      return await response.json();

      // this.setState({ simphoneimpresult: JSON.stringify(res, null, 4) });
    } catch (err) {
      console.log(err);
    }
  },
  async getPictures(input) {
    for (let i = 0; i < input.data.listECOLEAFPostss.items.length; i++) {
      if (
        input.data.listECOLEAFPostss.items[i].user !== null &&
        input.data.listECOLEAFPostss.items[i].user.profilepic !== null
      )
        input.data.listECOLEAFPostss.items[
          i
        ].user.profilepic = await this.getpicfroms3(
          input.data.listECOLEAFPostss.items[i].user.profilepic.replace(
            "large",
            "thumbs"
          )
        );
    }
    return input;
  },
  async getPicturesSELF(input) {
    for (let i = 0; i < input.length; i++) {
      if (input[i].user !== null && input[i].user.profilepic !== null)
        input[i].user.profilepic = await this.getpicfroms3(
          input[i].user.profilepic.replace("large", "thumbs")
        );
    }
    return input;
  },
  async savetos3(file) {
    let newfile = file.split(",")[1];
    let ctype = file.split(",")[0].split(";")[0];
    let ext = file.split(",")[0].split(";")[1].split(".")[1];
    if (ext === undefined) ext ="jpg"
    // console.log({ext})
    function create_UUID() {
      var dt = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    }
    return await Storage.put(
      "public/" + create_UUID() + "." + ext,
      Buffer.from(newfile, "base64"),
      { contentType: ctype }
    );
  },
  async saveSegmenttos3(file,name) {
    // console.log(file)
    // console.log(name)
    // return true
    return await Storage.put(
      "segment/" + name,
      file,
      { contentType: file.type }
    );
  },

  async BULKsavetos3(file) {
    // console.log(file)
    // console.log(name)
    // return true
    return await Storage.put(
      "segment/" + file.name,
      file,
      { contentType: file.type }
    );
  },

  async getpicfroms3(file) {
    try {
      let s = await Storage.get(file);
      return s;
    } catch (err) {
      console.log(err);
      return null;
    }
  },
  async genericGet(table, id) {
    // console.log({table,id})
    // console.log(queries[table])

    return await API.graphql(
      graphqlOperation(queries[table], {
        id,
      })
    );
  },
  async genericGetMOD(table, id, MOD) {
    return await API.graphql(
      graphqlOperation(queries[table].replace(MOD.before, MOD.after), {
        id,
      })
    );
  },
  async genericList(table, filter) {
    try {
      return await API.graphql(graphqlOperation(queries[table], filter));
    } catch (err) {
      console.log(err);
    }
  },
  async collectNewIdNumber() {
    const listVnaOrderss = /* GraphQL */ `
      query ListVnaOrderss {
        listVNAOrderss(limit: 10000) {
          items {
            id
          }
        }
      }
    `;
    let info = await API.graphql(graphqlOperation(listVnaOrderss));
    if (info.data.listVNAOrderss.items.length > 0) {
      info =
        info.data.listVNAOrderss.items
          .map((z) => (z = parseInt(z.id)))
          .sort((a, b) => b - a)[0] + 1;
      return info;
    } else return 1;
  },
  convertURLtoObject(url) {
    url = url.replace("?", "");
    url = url.split("&");
    let params = {};
    url.forEach((z) => {
      let item = z.split("=");
      params[item[0]] = item[1];
    });
    return params;
  },
  async ListOrdersWithLabelFilter(filter) {
    const listVnaOrderss = /* GraphQL */ `
      query ListVnaOrderss(
        $filter: ModelVNAOrdersFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listVNAOrderss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            active
            name
            label
            campaigns {
              name
              client {
                name
                label {
                  name
                }
              }
            }
            frequency
            dayhourindicator
            segments
            uriimage
          }
          nextToken
        }
      }
    `;
    try {
      return await API.graphql(graphqlOperation(listVnaOrderss, filter));
    } catch (err) {
      console.log(err);
    }
  },
};
export default Utils;
