import React from "react";
// import Utils from "../utils/utils";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [],
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async componentDidMount() {
    this.doSnackBar("Getting stats");
    // console.log(window.location.search);
    let tag = window.location.search;
    if (tag !== "") tag = tag.replace("?", "");
    tag = tag.split("&");
    tag = tag[0];
    tag = tag.replace("tag=", "");
    let s = await fetch(
      "https://l8rqkk68xl.execute-api.af-south-1.amazonaws.com/production/getcodessummary?func=getlog&code=" +
        tag.toLowerCase(),
      {
        method: "GET",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",

          Accept: "application/json",
        },
      }
    );
    let t = await s.json();
    // console.log({ t });

    t = t.map((z) => {
      return { date: z.date.value, impressions: z.count };
    });
    this.setState({ stats: t });
  }

  render() {
    return (
      <div style={{ margin: 20, color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={10000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        Usage: https://console.vnamobi.com/stats?tag=[TAG]
        <br />
        Example: https://console.vnamobi.com/stats?tag=lowerthanlow
        <br />
        <br />
        <br />
        <br />
        <Table style={{width:"30%"}}>
          <TableHead>
            <TableRow>
              <TableCell style={{width:"10%"}}>
                <b>Date</b>
              </TableCell>
                <TableCell style={{width:"10%"}}>
                <b>Total clicks</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.stats.map((z, idx) => (
              <TableRow key={idx}>
                <TableCell>{z.date}</TableCell>
                <TableCell>{z.impressions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
export default ClientPage;
