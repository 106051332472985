import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Utils from "../utils/utils";
import OrderDetail from "../components/orderdetail";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

class ClientPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authState: this.props.authState,
      edit: false,
      doonce: false,
      confirmersave: false,
      confirmerupdate: false,
      orders: [],
      idnumber: 0,
      campaigns: [],
      segments: [{ id: 1, name: "select", total: 0 }],
      segmentscombo: [],
      liveimpression: [],
      orderdata: {
        active: false,
        dayhourindicator: [],
        frequency: 99,
        id: "",
        billing:{type:"internal",value:50},
        name: "Primary",
        vNAOrdersCampaignsId: "",
        label: "",
        segments: [],
        text: "",
        uriimage: null,
        urlredirect: "https://google.com",
      },
      testMMSphone: "0642824967",
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.authState.state !== this.props.authState.state) {
      this.setState({ authState: this.props.authState });
      this.getorders();
    }
  }

  gentext(arr) {
    let output = arr.map((z) => String.fromCharCode(z)).join("  ");
    return output;
  }

  async sendMMS(testMMSphone) {
    this.doSnackBar("Sending message");

    let payload = {
      phone: testMMSphone.phone,
      pic: testMMSphone.pic,
      text: /*this.gentext([0x57])*/ testMMSphone.text,
    };

    let s = await fetch(
      "https://luqkgkokq4.execute-api.eu-west-1.amazonaws.com/production/sendmms?",
      // "phone=" +
      // testMMSphone.phone +
      // // "&pic=" +
      // // testMMSphone.pic +
      // "&text=" +
      // testMMSphone.text,
      {
        body: JSON.stringify(payload),
        method: "POST",
        mode: "cors",
        credentials: "same-origin",
      }
    );
    let resp = await s.json();
    if (
      resp.SendStandardMMSResult.mmsSubmitResult.resultDescription === "Success"
    )
      this.doSnackBar("Success");
    else this.doSnackBar("Failed");
  }

  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.state.authState.state !== "signedIn")
        this.props.history.push("/");
      else {
        this.getorders();
      }
    }, 1000);
  }
  async getliveimpressions(orders) {
    this.setState({ doonce: true });

    async function getstat(orderid) {
      let res = await Utils.doGet("livecounterorder" + orderid.id);
      // console.log(res);
      return { order: orderid.id, impressions: res };
    }
    let liveimpression = await Promise.all(
      orders.data.listVNAOrderss.items.map(getstat)
    );

    // console.log(liveimpression);
    this.setState({ liveimpression });
  }

  async getorders() {
    this.doSnackBar("Getting data");
    let querystringparams = Utils.convertURLtoObject(
      this.props.location.search
    );
    if (!querystringparams.campaign && !querystringparams.order)
      this.props.history.push("/manage");
    let segmentmaster = await Utils.doGet("segmentmaster");
    // console.log({segmentmaster})
    let segmentcounter = await Utils.doGet("segmentcounter");
    // console.log({segmentmaster})
    if (querystringparams.campaign) {
      let camp = await Utils.genericGet(
        "getVnaCampaigns",
        querystringparams.campaign
      );
      let orderdata = this.state.orderdata;
      orderdata.vNAOrdersCampaignsId = camp.data.getVNACampaigns.id;
      orderdata.label = camp.data.getVNACampaigns.client.label.id;
       //console.log({ orderdata });
      if (camp) this.setState({ campaigns: camp.data.getVNACampaigns });
      this.setState({
        orderdata,
        edit: false,
        label: camp.data.getVNACampaigns.client.label.id,
        segmentmaster,
        segmentcounter,
      });
    } else {
      let camp = await Utils.genericGet(
        "getVnaOrders",
        querystringparams.order
      );
      let orderdata = Object.assign({}, camp.data.getVNAOrders);
      orderdata.dayhourindicator = JSON.parse(orderdata.dayhourindicator);
      orderdata.segments = JSON.parse(orderdata.segments);
        orderdata.billing = JSON.parse(orderdata.billing);
      delete orderdata.createdAt;
      delete orderdata.updatedAt;
      delete orderdata.campaigns;
      if (orderdata.billing===null )orderdata.billing={type:"internal",value:50}
// console.log({orderdata})
      this.setState({
        orderdata,
        campaigns: camp.data.getVNAOrders.campaigns,

        edit: true,
        segmentmaster,
        segmentcounter,
      });
    }
  }
  async addOrder(item) {
    // console.log(item)
    // console.log(item.uriimage)
    if (item.uriimage !== null && item.uriimage.indexOf("public") === -1) {
      this.doSnackBar("uploading picture");

      let picuri = await Utils.savetos3(item.uriimage);
      item.uriimage = picuri.key;
    }
    let idnumber = await Utils.collectNewIdNumber();
    item.id = "" + idnumber;
    item.dayhourindicator = JSON.stringify(item.dayhourindicator);
      item.billing = JSON.stringify(item.billing);
    item.segments = JSON.stringify(item.segments);
    item.label = this.state.label;
    await Utils.genericMutation("createVnaOrders", item);
    // await fetch(
    //   "https://is3pkph3yf.execute-api.af-south-1.amazonaws.com/production/imageloader?instance=i-0966936e4abadde06"    // );
    this.doSnackBar("Added order");
    this.props.history.push("/manage");
  }
  async updateOrder(item) {
    // console.log("reached here")
    // console.log({item})
    // console.log({old:this.state.orderdata})

    if (item.uriimage !== null && item.uriimage.indexOf("public") === -1) {
      this.doSnackBar("uploading picture");
      let picuri = await Utils.savetos3(item.uriimage);



      item.uriimage = picuri.key;
      item.urlsenttoftp =false
    }
    item.dayhourindicator = JSON.stringify(item.dayhourindicator);
    item.segments = JSON.stringify(item.segments);
      item.billing = JSON.stringify(item.billing);
    await Utils.genericMutation("updateVnaOrders", item);
    // await fetch(
    //   "https://is3pkph3yf.execute-api.af-south-1.amazonaws.com/production/imageloader?instance=i-0966936e4abadde06"
    // );
    this.doSnackBar("Added updated");
    this.props.history.push("/manage");
  }

  async delOrder(id) {
    this.doSnackBar("deleting");
    await Utils.genericMutation("deleteVnaOrders", { id });
    this.componentDidMount();
  }
  render() {
    return (
      <div style={{ margin: 20, color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Dialog
          open={this.state.confirmersave}
          onClose={() => this.setState({ confirmersave: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Would you like to also activate the order?"}
          </DialogTitle>

          <DialogActions>
            <Button
              onClick={() => this.setState({ confirmersave: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.addOrder(Object.assign({}, this.state.orderdata));
                this.setState({ confirmersave: false, neworderName: "" });
              }}
              color="primary"
            >
              No. Stay off.
            </Button>
            <Button
              onClick={() => {
                let ord = Object.assign({}, this.state.orderdata);
                ord.active = true;
                this.addOrder(ord);
                this.setState({ confirmersave: false, neworderName: "" });
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.confirmerupdate}
          onClose={() => this.setState({ confirmerupdate: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Would you like to also activate the order?"}
          </DialogTitle>

          <DialogActions>
            <Button
              onClick={() => this.setState({ confirmerupdate: false })}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.updateOrder(Object.assign({}, this.state.orderdata));
                this.setState({ confirmerupdate: false, neworderName: "" });
              }}
              color="primary"
            >
              No. Stay off.
            </Button>
            <Button
              onClick={() => {
                let ord = Object.assign({}, this.state.orderdata);
                ord.active = true;
                this.updateOrder(ord);
                this.setState({ confirmerupdate: false, neworderName: "" });
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <OrderDetail
          input={this.state.orderdata}
          segmentmaster={this.state.segmentmaster}
          segmentcounter={this.state.segmentcounter}
          campaigns={this.state.campaigns}
          onChange={(change) =>{
            // console.log({change})
             this.setState({ orderdata: change })}}
        />


        {this.state.edit ? (
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.edit}
            onClick={() => {
              if (this.state.orderdata.active === true) {
                // console.log({order:this.state.orderdata})
                this.updateOrder(Object.assign({}, this.state.orderdata));
                this.setState({ neworderName: "" });
              }
              else this.setState({ confirmerupdate: true });
              // this.setState({ confirmerupdate: true });
              // this.updateOrder(Object.assign({}, this.state.orderdata));
              // this.setState({ neworderName: "" });
            }}
          >
            update existing order
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={this.state.edit}
            color="primary"
            onClick={() => {
              if (this.state.orderdata.active === true) {
                this.addOrder(Object.assign({}, this.state.orderdata));
                this.setState({ neworderName: "" });
              }
              else this.setState({ confirmersave: true });
            }}
          >
            Add new order
          </Button>
        )}
      </div>
    );
  }
}
export default withRouter(ClientPage);
