import React from "react";
import Header from "./components/header";

// import MainPage from "./pages/mainpage";
import ClientPage from "./pages/client";
import PreviewPage from "./pages/preview";
import CampaignPage from "./pages/campaign";
import LabelPage from "./pages/label";
import OrderPage from "./pages/order";
import StatsPage from "./pages/stats";
import AdminPage from "./pages/admin";
import SegmentPage from "./pages/segmentupload";
import ManagePage from "./pages/manage";

import ServerPage from "./pages/servercommands";

import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      authState: { state: "", usr: "" },
    };
  }
  render() {
    return (
      <Router>
        <Header authState={(authState) => this.setState({ authState })} />

        <Route path="/" exact>
          <PreviewPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
        <Route path="/order">
          <OrderPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
        <Route path="/stats">
          <StatsPage />
        </Route>
        <Route path="/admin">
          <AdminPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
        <Route path="/segmentupload">
          <SegmentPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
        <Route path="/manage">
          <ManagePage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
        <Route path="/preview">
          <PreviewPage />
        </Route>
        <Route path="/client">
          <ClientPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
        <Route path="/label">
          <LabelPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>

        <Route path="/campaign">
          <CampaignPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
        <Route path="/servercommands">
          <ServerPage
            authState={this.state.authState}
            search={this.state.search}
          />
        </Route>
      </Router>
    );
  }
}

export default App;
